export default {
    inject: ['moshaToast'],
    data() {
        return {
            API_URL: process.env.VUE_APP_API_URL || 'https://api.cdmsell.it',

            /* API_URL: 'https://api.cdmsell.it', */
            /* API_URL: 'http://192.168.1.170:3000', */   // CORS lato BE configurato per tutte le chiamate
        };
    },
    methods: {

        formatPrice(value) {
            return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);
        },

        daSviluppare(titolo = "Handle non Implementato") {
            this.moshaToast(
                {
                    title: titolo,
                    description: `Sviluppo EOS in Corso...`
                },
                {
                    showIcon: true,
                    hideProgressBar: false,
                    timeout: 6000,
                    type: 'info',
                    toastBackgroundColor: '#292b2c',
                    transition: 'bounce',
                    showCloseButton: true,
                    swipeClose: true,
                    position: 'bottom-right'
                }
            );
        },
    }
}