const ListaInstallazioni = () => import('@/pages/core/installazione/ListaInstallazioni.vue');
const ModificaInstallazione = () => import('@/pages/core/installazione/ModificaInstallazione.vue');
const CreaInstallazione = () => import('@/pages/core/installazione/CreaInstallazione.vue');

const routes = [
    { 
        name: 'installazione.index',
        path: '/installazione', 
        component: ListaInstallazioni,
        meta: {
            titolo: 'Lista Installazioni',
            requiresAuthentication: true,
            dashboard: false,
            permesso: ['installazione.index', 'installazione.utente.index']
        }
    },
    { 
        name: 'installazione.edit',
        path: '/installazione/:installazioneId/edit', 
        component: ModificaInstallazione,
        meta: {
            titolo: 'Installazione',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'installazione.show'
        }
    },
    { 
        name: 'installazione.create',
        path: '/installazione/create/:clienteId?/:ordineId?', 
        component: CreaInstallazione,
        meta: {
            titolo: 'Nuova Installazione',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'installazione.create'
        }
    },
]

export default routes;