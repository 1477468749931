const ListaFase = () => import('@/pages/dashboard_globale/area/amministrazione/fase/ListaFase.vue');
const CreaFase = () => import('@/pages/dashboard_globale/area/amministrazione/fase/CreaFase.vue');
const ModificaFase = () => import('@/pages/dashboard_globale/area/amministrazione/fase/ModificaFase.vue');

const routes = [
    {
        name: 'fase.index',
        path: '/dashboard/fase',
        component: ListaFase,
        meta: {
            titolo: 'Fase Dettaglio Prodotto Ordine',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'fase.create',
        path: '/dashboard/fase/create',
        component: CreaFase,
        meta: {
            titolo: 'Crea Fase Dettaglio Prodotto Ordine',
            requiresAuthentication: true,
            dashboard: true
        }
    },
    {
        name: 'fase.edit',
        path: '/dashboard/fase/:faseId/edit',
        component: ModificaFase,
        meta: {
            titolo: 'Modifica Fase Dettaglio Prodotto Ordine',
            requiresAuthentication: true,
            dashboard: true
        }
    },
]

export default routes;